<template>
  <div class="container">
    <van-sticky>
      <van-nav-bar title="媒体广告" left-arrow @click-left="$router.back()">
      </van-nav-bar>
    </van-sticky>

     <van-search
      v-model="pagePara.filterData.channelActivityName"
      @search="onSearch"
      placeholder="请输入搜索关键词"
    />



    <van-list
      v-model="upLoading"
      :finished="finished"
      @load="onLoad"
      finished-text="没有更多了"
    >
      <van-cell-group>
        <van-cell v-for="item in articles" :key="item.channelActivityName" @click="toListDetail(item.channelActivityName)" is-link >
          <van-col span="24">
            <div style="
    font-size: 14px;">
            {{ item.channelActivityName }}
            </div>
          </van-col>
        </van-cell>
      </van-cell-group>
    </van-list>
  </div>
</template>

<script>
import { getAdDistinctList } from "@/api/ad"; 
//import { getArticleList } from "@/api/article";

//const key = "zn-history";
export default {
  name: "ad",
  data() {
    return {
      keyword: "",
      upLoading: false,
      finished: false,
      articles: [],
      pagePara: {
        current: 1,
        pageSize: 20,
        filterData:{
           channelActivityName:"",
        }
      
        
      },
    };
  },
  components: {},
  created() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.query != from.query) {
        this.initData();
        this.onSearch();
      }
    },
  },
  mounted() {},

  methods: {
    async initData() {},

    onSearch() {
      this.articles = [];
      this.pagePara.current = 1;
      this.pagePara.pageSize = 20;
      this.onLoad();
    },

    async onLoad() {
      let aresult = await getAdDistinctList({ ...this.pagePara });

      this.articles.push(...aresult.data.data);
      this.upLoading = false;
      if (this.pagePara.current * this.pagePara.pageSize < aresult.data.total) {
        this.pagePara.current++;
        this.finished = false;
      } else {
        this.finished = true;
      }
    },
    toListDetail(name) {
      this.$router.push({
        path: "/ad/list",
        query: { name: name},
      });
    },
  },
};
</script>

<style lang="less" scoped>
.w100 {
  /deep/ img {
    width: 100%;
    max-height: 200px;
  }
}
.zntitle {
  height: 80px;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.detail {
  padding: 46px 10px 44px;
  .title {
    font-size: 18px;
    line-height: 2;
  }
  .zan {
    text-align: center;
    padding: 10px 0;
    .active {
      border-color: red;
      color: red;
    }
  }
  .author {
    padding: 10px 0;
    display: flex;
    position: sticky;
    background-color: #fff;
    top: 46px;
    z-index: 2;
    .text {
      flex: 1;
      padding-left: 10px;
      line-height: 1.5;
      .name {
        font-size: 14px;
        margin: 0;
      }
      .time {
        margin: 0;
        font-size: 12px;
        color: #999;
      }
    }
  }
  .content {
    padding: 20px 0;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;
    /deep/ img {
      max-width: 100%;
      background: #f9f9f9;
    }
    /deep/ code {
      white-space: pre-wrap;
    }
  }
}
</style>
