import request from '@/utils/request'

//投放广告产品详情
export function getAdDistinctList (params) {
  return request({
    url: '/api/Activity/GetDistinctList',
    data: { ...params },
    method: 'post',
    header:{
      'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
    },
    
  })
}

//投放广告产品列表
export function getAdList (params) {
    return request({
      url: '/api/Activity/GetList',
      data: { ...params },
      method: 'post',
      header:{
        'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
      },
      
    })
}

